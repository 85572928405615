import React, { useState } from "react";
import fineTunedFeatures from "./features/fine_tuned_features.json";
import pretrainedFeatures from "./features/pre_trained_features.json"; 
import FeatureDetailsSidebar from "./components/FeatureDetailsSidebar";
import fineTunedScoreBreakdown from "./feature_scores/fine_tuned_features_score_breakdown.json";
import pretrainedScoreBreakdown from "./feature_scores/pre_trained_features_score_breakdown.json"; 
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function AutoEdCoderScoredFeatures() {
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [modelType, setModelType] = useState("fine-tuned");
  const [openModal, setOpenModal] = useState(false);

  const features = modelType === "fine-tuned" ? fineTunedFeatures : pretrainedFeatures;
  const scoreBreakdown = modelType === "fine-tuned" ? fineTunedScoreBreakdown : pretrainedScoreBreakdown;

  const renderFeatureColumn = (score) => {
    const columnFeatures = scoreBreakdown[score];
    const featureCount = columnFeatures.length;
    
    return (
      <div className="flex-1 bg-white rounded-lg p-4 shadow-sm overflow-auto">
        <h2 className="text-xl font-bold mb-4">
          {score === 0 ? `Poor Features (${featureCount})` :
           score === 1 ? `Average Features (${featureCount})` :
           `Good Features (${featureCount})`}
        </h2>
        {columnFeatures.map((feature, index) => {
          const featureData = features.find(f => f.index === feature.feature_index);
          return (
            <div 
              key={index}
              className={`mb-2 p-2 rounded cursor-pointer ${
                selectedFeature === feature ? 'bg-orange-200' : 
                hoveredFeature === feature ? 'bg-orange-100' : 'bg-gray-100'
              }`}
              onMouseEnter={() => setHoveredFeature(feature)}
              onMouseLeave={() => setHoveredFeature(null)}
              onClick={() => setSelectedFeature(feature)}
            >
              <p className="font-semibold">{featureData.label}</p>
              <p className="text-sm">{featureData.attributes}</p>
              <p className="text-sm text-right opacity-50">{Math.round(feature.perc_score)}% of samples</p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen p-4">
      <div className="absolute top-4 left-4">
        <a className="text-gray-500 underline cursor-pointer hover:text-gray-700 transition-colors duration-300" onClick={() => setOpenModal(true)}>
          Question Details
        </a>
      </div>
      <div className="absolute top-4 right-4">
        <select 
          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          value={modelType}
          onChange={(e) => setModelType(e.target.value)}
        >
          <option value="fine-tuned">BERT Fine-Tuned Classification Model</option>
          <option value="pretrained">BERT Pretrained Model</option>
        </select>
      </div>
      <div className="flex-shrink-0 mb-4">
        <h1 className="text-black-primary font-eb-garamond text-3xl my-8 text-center">
          What features are the most indicative signals of a good/average/bad response?
        </h1>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box className="bg-white p-4 rounded-lg shadow-lg max-w-5xl mx-auto mt-20">
            <h2 id="modal-title" className="text-xl font-bold mb-4">Explanation of the Question</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p id="modal-description" className="mb-4">
                  Explain how pandas in China are similar to koalas in Australia and how they both are different from pythons. Support your response with information from the article.
                </p>
                <p className="mb-2 font-semibold">To answer the prompt effectively, the key points should include:</p>
                <ul className="list-disc list-inside mb-4">
                  <li>Similarities between Pandas and Koalas:</li>
                  <ul className="list-disc list-inside ml-4">
                    <li>Both pandas and koalas are specialists.</li>
                    <li>Pandas eat almost nothing but bamboo.</li>
                    <li>Koalas eat eucalyptus leaves almost exclusively.</li>
                  </ul>
                  <li>Differences from Pythons:</li>
                  <ul className="list-disc list-inside ml-4">
                    <li>Pandas and koalas have specialized diets and habitats.</li>
                    <li>Pythons are generalists and can adapt to various environments.</li>
                    <li>Pythons have become invasive species, spreading to new areas like the Everglades.</li>
                  </ul>
                  <li>Supporting Information from the Article:</li>
                  <ul className="list-disc list-inside ml-4">
                    <li>Mention of pandas' and koalas' dietary specializations.</li>
                    <li>Explanation of generalists versus specialists.</li>
                    <li>Details about the impact of invasive species like pythons on biodiversity.</li>
                    <li>Quotes or paraphrases from Maclnnes and Snow on the broader environmental implications.</li>
                  </ul>
                </ul>
              </div>
              <div>
                <p className="mb-2 font-semibold">Rubric:</p>
                <ul className="list-disc list-inside mb-4">
                  <li><strong>Score Point 2:</strong> The response demonstrates: an exploration or development of the ideas presented in the text a strong conceptual understanding by the inclusion of specific relevant information from the text an extension of ideas that may include extensive and/or insightful inferences, connections between ideas in the text, and references to prior knowledge and/or experiences</li>
                  <li><strong>Score Point 1:</strong> The response demonstrates: some exploration or development of ideas presented in the text a fundamental understanding by the inclusion of some relevant information from the text an extension of ideas that lacks depth, although may include some inferences, connections between ideas in the text, or references to prior knowledge and/or experiences</li>
                  <li><strong>Score Point 0:</strong> The response demonstrates: limited or no exploration or development of ideas presented in the text limited or no understanding of the text, may be illogical, vague, or irrelevant possible incomplete or limited inferences, connections between ideas in the text, or references to prior knowledge and/or experiences</li>
                </ul>
              </div>
            </div>
            <div className="text-right">
              <a href="#" onClick={() => setOpenModal(false)} className="text-gray-400 underline">
                Close
              </a>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="flex flex-row gap-4 flex-grow overflow-hidden">
        <div className="w-2/3 flex gap-4">
          {renderFeatureColumn(0)}
          {renderFeatureColumn(1)}
          {renderFeatureColumn(2)}
        </div>
        <div className="w-1/3 flex flex-col">
          <div className="bg-white rounded-lg p-4 shadow-sm overflow-auto flex-grow">
            <FeatureDetailsSidebar selectedFeature={hoveredFeature || selectedFeature} indexKey="feature_index" modelType={modelType} fixedHeight={false} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutoEdCoderScoredFeatures;
