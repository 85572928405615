import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import sanityClient from "../client.js";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
import { getImageDimensions } from "@sanity/asset-utils";
import ReactPlayer from "react-player";
import { motion, AnimatePresence } from "framer-motion";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const SampleImageComponent = ({ value }) => {
  const { width, height } = getImageDimensions(value);
  return (
    <img
      src={urlFor(value)}
      alt={value.alt || " "}
      loading="lazy"
      style={{
        aspectRatio: width / height,
      }}
    />
  );
};

export default function LabPost() {
  const [postData, setPostData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image,
        publishedAt
       }`,
        { slug }
      )
      .then((data) => {
        setPostData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, [slug]);

  if (isLoading) return <></>;
  if (!postData) return <div>No post found</div>;

  const formattedDate = postData.publishedAt
    ? new Date(postData.publishedAt).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "";

  return (
    <div
      className="max-w-[800px] px-12 mx-auto my-0 my-12 tablet:my-36"
      id="container"
    >
      <button className="link mb-4" onClick={() => navigate("/the-lab")}>
        Back
      </button>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col gap-4 items-start mb-16"
        >
          <div className="text-left w-full font-eb-garamond gap-2 flex flex-col">
            <h1 className="text-black-primary font-eb-garamond text-4xl my-4">
              {postData.title}
            </h1>

            <p className="text-black opacity-50 mb-4">{formattedDate}</p>
            <PortableText
              value={postData.body}
              components={{
                block: {
                  h1: ({ children }) => (
                    <h1 className="text-2xl font-bold mt-6 mb-4">{children}</h1>
                  ),
                  h2: ({ children }) => (
                    <h2 className="text-xl font-bold mt-4 mb-2">{children}</h2>
                  ),
                  h3: ({ children }) => (
                    <h3 className="text-lg font-bold mt-4 mb-2">{children}</h3>
                  ),
                  normal: ({ children }) => <p className="mb-4">{children}</p>,
                  blockquote: ({ node: { children } }) => (
                    <div className="bg-white rounded-lg p-4 shadow-sm mb-4">
                      {children.map((child, index) => (
                        <p key={index}>{child["text"]}</p>
                      ))}
                    </div>
                  ),
                },
                list: {
                  bullet: ({ children }) => (
                    <ul className="list-disc ml-6 list-outside mb-4">
                      {children}
                    </ul>
                  ),
                  number: ({ children }) => (
                    <ol className="list-decimal list-outside mb-4 ml-6">
                      {children}
                    </ol>
                  ),
                },
                listItem: {
                  bullet: ({ children }) => (
                    <li className="mb-2">{children}</li>
                  ),
                  number: ({ children }) => (
                    <li className="mb-2">{children}</li>
                  ),
                },
                marks: {
                  link: ({ value, children }) => {
                    const target = (value?.href || "").startsWith("http")
                      ? "_blank"
                      : undefined;
                    return (
                      <a
                        href={value?.href}
                        target={target}
                        rel={
                          target === "_blank" ? "noindex nofollow" : undefined
                        }
                        className="link"
                      >
                        {children}
                      </a>
                    );
                  },
                  strong: ({ children }) => <strong>{children}</strong>,
                  em: ({ children }) => <em>{children}</em>,
                },
                types: {
                  image: SampleImageComponent,
                  divider: () => (
                    <hr className="border-t border-black opacity-30 my-8" />
                  ),
                  youtube: ({ value }) => {
                    const { url } = value;
                    return <ReactPlayer url={url} />;
                  },
                },
              }}
            />
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
